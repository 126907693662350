import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';
import { useMemo, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';

import {
  type ModelsGamePackPrice,
  type ModelsSettingsOneTimePurchase,
} from '@lp-lib/api-service-client/public';

import { DeleteIcon } from '../components/icons/DeleteIcon';
import { PlusIcon } from '../components/icons/PlusIcon';
import { useInstance } from '../hooks/useInstance';
import { AdminView } from '../pages/Admin/AdminView';
import { AdminToolkitNav } from '../pages/Admin/Toolkit';
import { apiService } from '../services/api-service';
import { uuidv4 } from '../utils/common';
import { tokenWithRedirect } from '../utils/router';

export const clientLoader = async (action: ClientLoaderFunctionArgs) => {
  const resp = await tokenWithRedirect(
    () => apiService.settings.getData('one-time-purchase'),
    action.request.url,
    { admin: true }
  );
  return resp.data.data;
};

type FormData = {
  pricingTable: ModelsSettingsOneTimePurchase['pricingTable'];
};

function OTPDefaultPricingTable() {
  const settings = useLoaderData<typeof clientLoader>();
  const form = useForm<FormData>({
    defaultValues: {
      pricingTable: settings.pricingTable,
    },
  });

  const [editing, setEditing] = useState(false);

  const { formState, register, control, handleSubmit, reset, getValues } = form;

  const {
    fields: prices,
    append,
    remove,
    update,
    replace,
  } = useFieldArray({
    control,
    name: 'pricingTable',
    keyName: 'key',
    rules: {
      validate: {
        validatePrices: (values) => {
          return (
            values.every(
              (value) => value.maxPlayers >= 0 && value.amount > 0
            ) || 'Invalid price table, Max Players >= 0 and Price > 0'
          );
        },
      },
    },
  });

  const newPriceIdSet = useInstance(() => new Set<string>());

  const addPriceEntry = (price: ModelsGamePackPrice) => {
    append(price);
    newPriceIdSet.add(price.id);
  };

  const handleAddPrice = () => {
    // useFieldArray can not reveal the latest values immediately
    const price = getValues('pricingTable').sort(
      (a, b) => b.maxPlayers - a.maxPlayers
    )[0];
    if (!price) {
      addPriceEntry({
        id: uuidv4(),
        maxPlayers: 50,
        amount: 100,
        archived: false,
      });
    }
    addPriceEntry({
      id: uuidv4(),
      maxPlayers: price.maxPlayers + 50,
      amount: price.amount + 100,
      archived: false,
    });
  };

  const handleDeletePrice = (
    index: number | undefined,
    price: ModelsGamePackPrice
  ) => {
    if (index === undefined) return;
    if (newPriceIdSet.has(price.id)) {
      remove(index);
    } else {
      update(index, { ...price, archived: true });
    }
  };

  const handleUnarchivePrice = (
    index: number | undefined,
    price: ModelsGamePackPrice
  ) => {
    if (index === undefined) return;
    update(index, { ...price, archived: false });
  };

  const handleSave = handleSubmit(async (data: FormData) => {
    await apiService.settings.updateValue('one-time-purchase', {
      key: 'pricingTable',
      value: data.pricingTable,
    });
    replace(data.pricingTable);
    setEditing(false);
    newPriceIdSet.clear();
  });

  const handleCancel = () => {
    reset();
    setEditing(false);
    newPriceIdSet.clear();
  };

  const sortedPrices = useMemo(() => {
    const pricesWithIndex = prices.map((price, index) => ({ price, index }));
    return pricesWithIndex.sort((a, b) => {
      if (a.price.archived && !b.price.archived) return 1;
      if (!a.price.archived && b.price.archived) return -1;
      return a.price.maxPlayers - b.price.maxPlayers;
    });
  }, [prices]);

  const { errors, isSubmitting } = formState;
  const error = errors.pricingTable?.root;

  return (
    <FormProvider {...form}>
      <form>
        <div className='relative w-full text-white flex flex-col gap-10'>
          <header className='flex justify-between items-center pb-4'>
            <h1 className='text-3.5xl font-bold'>
              One Time Purchase Default Pricing Table
            </h1>
          </header>
          <div className='flex flex-col gap-4'>
            <div className='flex items-center gap-4'>
              <button
                type='button'
                className='btn flex justify-center items-center text-primary gap-1 text-sm hover:underline'
                onClick={handleAddPrice}
                disabled={isSubmitting || !editing}
              >
                <PlusIcon />
                Add Price
              </button>
            </div>
            {sortedPrices.length === 0 ? (
              <div className='w-full py-6 flex items-center justify-center text-icon-gray'>
                No prices
              </div>
            ) : (
              <table className='w-full table-fixed border-collapse'>
                <thead>
                  <tr className={`${error ? 'text-red-002' : 'text-white'}`}>
                    <th className='text-left py-2 border-b border-secondary w-[40%]'>
                      Max Players
                    </th>
                    <th className='text-left py-2 border-b border-secondary'>
                      Price
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedPrices.map(({ price, index }) => (
                    <tr
                      key={price.id}
                      className={`hover:bg-dark-gray ${
                        price.archived ? 'opacity-60' : 'opacity-100'
                      }`}
                    >
                      <td className='text-left text-icon-gray py-3 pl-3 pr-6 border-b border-black-001'>
                        {editing ? (
                          <input
                            type='number'
                            className='w-full h-10 field mb-0'
                            autoComplete='off'
                            {...register(`pricingTable.${index}.maxPlayers`, {
                              valueAsNumber: true,
                            })}
                            disabled={price.archived}
                          />
                        ) : (
                          <span>{price.maxPlayers}</span>
                        )}
                      </td>
                      <td className='text-left text-icon-gray py-3 border-b border-black-001'>
                        <div className='flex items-center gap-2 pr-6'>
                          <span className='font-bold'>$</span>
                          {editing ? (
                            <input
                              type='number'
                              className='w-1/2 h-10 field mb-0'
                              autoComplete='off'
                              key={price.id}
                              {...register(`pricingTable.${index}.amount`, {
                                valueAsNumber: true,
                              })}
                              disabled={price.archived}
                            />
                          ) : (
                            <span>{price.amount}</span>
                          )}
                          {price.archived && (
                            <div className='ml-auto flex flex-col items-center justify-center'>
                              <div className='text-2xs text-icon-gray uppercase tracking-wide font-bold'>
                                Archived
                              </div>
                              {editing && (
                                <button
                                  type='button'
                                  className='btn text-primary underline text-3xs'
                                  onClick={() =>
                                    handleUnarchivePrice(index, price)
                                  }
                                  disabled={isSubmitting}
                                >
                                  Undo
                                </button>
                              )}
                            </div>
                          )}
                          {!price.archived && editing && (
                            <button
                              type='button'
                              className='ml-auto text-red-001'
                              onClick={() => handleDeletePrice(index, price)}
                              disabled={isSubmitting}
                            >
                              <DeleteIcon />
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {error && (
              <div className='text-sms text-red-002'>{error.message}</div>
            )}
          </div>
          <footer className='flex items-center gap-4'>
            {editing ? (
              <>
                <button
                  type='button'
                  className='btn-secondary w-40 h-10'
                  onClick={handleCancel}
                  disabled={isSubmitting}
                >
                  Cancel
                </button>
                <button
                  type='button'
                  className='btn-primary w-40 h-10'
                  onClick={handleSave}
                  disabled={isSubmitting}
                >
                  Save
                </button>
              </>
            ) : (
              <button
                type='button'
                className='btn-primary w-50 h-10'
                onClick={() => setEditing(true)}
              >
                Edit Pricing Table
              </button>
            )}
          </footer>
        </div>
      </form>
    </FormProvider>
  );
}

export function Component() {
  return (
    <AdminView className='bg-library-2023-07 p-10 flex flex-col gap-10'>
      <AdminToolkitNav />

      <OTPDefaultPricingTable />
    </AdminView>
  );
}
